
import {
  apiGameAppLists,
  apiShopSwitchStatus,
  apiGameAppDelete,
  apiChangeRemark
} from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    DatePicker
  }
})
export default class GameAppList extends Vue {
  /** S Data **/

  apiGameAppLists = apiGameAppLists;

  pager: RequestPaging = new RequestPaging();

  searchObj: any = {
    sid: '',
    status: '',
    shop_info: '',
    start_time: '',
    end_time: '',
    time_type: ''
  };


  // 获取游戏应用列表
  getGameAppLists (): void {
    this.pager.request({
      callback: apiGameAppLists,
      params: {
        ...this.searchObj,
        sid: this.$route.query.sid
      }
    })
  }

  // 修改游戏应用状态
  async shopSwitchStatusEdit (status: number, id: number): Promise<void> {
    await apiShopSwitchStatus({ id })
    this.getGameAppLists()
  }

  async changeRemarks (text: string, id: number): Promise<void> {
    await apiChangeRemark({ id: id, remark: text })
    this.getGameAppLists()
  }

  // 删除游戏应用
  async onShopDelete (row: any): Promise<void> {
    await apiGameAppDelete({ id: row.id })
    this.getGameAppLists()
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.searchObj).map((key) => {
      if (key === 'sid') return
      this.$set(this.searchObj, key, '')
    })
    this.getGameAppLists()
  }

  // 去编辑游戏应用
  toGameAppEdit (id: number | any): void {
    this.$router.push({
      path: '/shop/game_app_detail',
      query: {
        id: id
      }
    })
  }

  // 修改配置
  toGameConfigUpdate (id: number | any): void {
    this.$router.push({
      path: '/shop/game_config',
      query: {
        id: id
      }
    })
  }

  /** E Methods **/

  /** S ods **/
  created () {
    this.searchObj.sid = this.$route.query.sid
    this.getGameAppLists()
  }
}
